@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

/* Root CSS */

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif !important;
}

:root {
  --new-bg-color: #F8FEFF;
  --bg-color: rgba(40, 52, 62, 0.07);
  --text-color: #242d49;
  --content-color: rgba(255, 255, 255, 1);
  --shadow-color: 0px 4px 17px 2px rgba(0, 0, 0, 0.25);
  --success-color: #28a745;
  --failure-color: #dc3545;
  --warning-color: #ffc107;
  --primary-color: #007bff;
  --info-color: #17a2b8;
  --dark-color: #343a40;
  --border-color: rgb(76, 168, 198);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--new-bg-color);
  color: var(--text-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a, a:hover, a:focus, a:active {
  text-decoration: none;
  color: inherit;
}

h1 { text-align: center; }

::-webkit-scrollbar {
  width: 0.4em;
  height: 0.4em;
}

::-webkit-scrollbar-thumb {
  background-color: #787878;
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  background: #d7d7d7;
  border-radius: 20px;
}