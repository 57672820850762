.fontFamily-Montserrat{
  font-family: "Montserrat", sans-serif !important;
}

/* navbar */
.navbar .navHeader{
  display: flex;
  height: 28px;
}

.navbar .MuiChip-root{
  border: none;
  border-radius: unset;
  font-size: 0.85rem;
}

.navbar .signedOutNavMenuDiv,
.signedOutNavMenu{
  display: none;
}

/* MainContent CSS */

/* sidebar */
.mainContent .sidebarContent{
  display: flex;
  flex-direction: column;
  min-width: 15%;
  background-color: #fff;
  box-shadow: 0 24px 24px 0 #00000040;
  -moz-box-shadow: 0 24px 24px 0 #00000040;
  -webkit-box-shadow: 0 24px 24px 0 #00000040;
}

.mainContent {
  display: flex;
  min-height: calc(100vh - 64px);
}

.mainContent .content {
  display: flex;
  flex-direction: column;
  background-color: var(--content-color);
  padding: 1rem 2rem;
  gap: 1rem;
  border-radius: 1rem;
  margin: 1rem;
  width: 100%;
  box-shadow: 0px 4px 24px 0px #00000040;
  -moz-box-shadow: 0px 4px 24px 0px #00000040;
  -webkit-box-shadow: 0px 4px 24px 0px #00000040;
}

.mainContent .content .blankScreen {
  padding: 1.5rem;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}

/* innerContent Style */

.mainContent .content .innerContent {
  display: flex;
  flex-direction: column;
}

.mainContent .content .innerContent .filters{
  display: flex;
  /* flex-direction: row-reverse; */
  align-items: center;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 5px;
  flex-wrap: wrap;
}

.mainContent .content .innerContent .filters .forms{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100%;
}

/* .mainContent .content .innerContent .filters .currentFilters{
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
} */

.mainContent .content .innerContent .table{
  width: 100%;
}

/* countTile */
.countTileContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: normal;
  align-items: center;
  gap: 1rem;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}

.countTileContainer .countTile {
  border-radius: 10px;
  display: flex;
  padding: 0.75rem;
  gap: 1rem;
  color: var(--content-color);
  background-color: #007bff;
  opacity: 0.8;
}

.countTileContainer .countTile .countTileLeft, 
.countTileContainer .countTile .countTileRight{
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-align: center;
}

/* Super Admin*/

/* test content */
.mainContent .content .testContent .tabs{
  display: flex; 
  flex-direction: row-reverse; 
  padding: 0.5rem 0;
  gap: 0.5rem;   
}

/* region content */

/* Admin */


/* report css */
.mainContent .content .report .chartArea .charts{
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  height: 70vh;
  /* width: 75vw; */
  position: relative;
  padding-top: 1.5rem;
}

/* facilitator view */

/* report */

.mainContent .content .report .chartArea .topTabs {
  display: flex; 
  flex-wrap: wrap-reverse;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem; 
}

.mainContent .content .report .chartArea .wordCloud{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding-top: 1.5rem; */
}

/* For visx wordcloud */
.mainContent .content .report .chartArea .wordCloud text{
  font-family: Impact !important;
}

.mainContent .content .report .chartArea .wordCloud svg {
  margin: 1rem 0;
  cursor: pointer;
}
/* For visx wordcloud */

.mainContent .content .report .chartArea .wordCloud .simpleCloud{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 60vw;
  color: rgba(0,0,0,0.2);
  border-radius: 15px;
  border: 1px solid;
}

.mainContent .content .report .chartArea .wordCloud h3{
  padding-top: 1.5rem;
  padding-bottom: 1rem;
}

/* studentReport */

.studentReport {
  display: flex;
  flex-direction: column;
}

 .studentReport .reportContainer{
  display: flex; 
  gap: 1rem;
}

.studentReport .reportContainer .reportContainerLeft{
  display: flex;
  flex-direction: column; 
  flex: 0.8; 
  gap: 1rem; 
  margin-top: 0.5rem;
  max-width: 886px;
}

.studentReport .reportContainer .reportContainerLeft .paraContainerTop p{
  font-size: 14px; 
  background-color: #0288d1; 
  color: #fff; 
  border-radius: 10px; 
  padding: 12px;
  overflow-x: auto;
}

.studentReport .reportContainer .reportContainerLeft .paraContainer{
  display: flex; 
  gap: 0.5rem; 
  flex-wrap: wrap; 
  background-color: #F1F3F9; 
  border-radius: 10px; 
  padding: 1rem;
}

.studentReport .reportContainer .reportContainerLeft .paraContainer .paraDiv{
  display: flex; 
  flex-wrap: wrap;
  align-items: center; 
  gap: 1rem;
}

.studentReport .reportContainer .reportContainerRight{
  display: flex; 
  flex-direction: column; 
  flex: 0.2; 
  margin-top: 0.5rem; 
  gap: 1rem;
}
.studentReport .reportContainer .reportContainerRight .attemptContainer,
.studentReport .reportContainer .reportContainerRight .legendContainer{
  display: flex; 
  flex-direction: column; 
  background-color: #F1F3F9; 
  border-radius: 10px; 
  padding: 1rem; 
  gap: 1rem;
}

/* students content*/

.mainContent .content .studentsContent .tabs{
  display: flex; 
  flex-direction: row-reverse; 
  padding-bottom: 0.5rem;
  gap: 0.5rem;   
}

/* excel upload */

.mainContent .content .excelUpload .upload {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.mainContent .content .excelUpload .upload .uploadFile {
  padding: 1rem;
  border: 2px dashed var(--border-color);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-grow: 1;
}

.mainContent .content .excelUpload .uploadStatus{
  border-radius: 10px;
  max-height: 82vh;
}

.mainContent .content .excelUpload .uploadStatus img{
  width: 70%;
  height: auto;
  margin-left: 1rem;
}

.mainContent .content .excelUpload .uploadInstructions{
  border-radius: 10px;
}

.mainContent .content .excelUpload .uploadStatus .MuiAlert-message,
.mainContent .content .excelUpload .uploadInstructions .MuiAlert-message{
  width: 100%;
}

/* Intervention */


/* privacy policy */
.mainContent .content .privacyPolicy{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

.mainContent .content .privacyPolicy .privacyPolicyDiv{
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.mainContent .content .privacyPolicy .privacyPolicyDiv p{
  font-size: 14px;
  text-align: justify;
}

.mainContent .content .privacyPolicy .privacyPolicyDiv ul{
  padding-left: 2rem;
  font-size: 14px;
  text-align: justify;
}

.mainContent .content .privacyPolicy .privacyPolicyDiv li{
  padding-bottom: 0.5rem;
}

.mainContent .content .privacyPolicy .privacyPolicyDiv li:last-child{
  padding-bottom: 0rem;
}

.mainContent .content .privacyPolicy .privacyPolicyDiv .privacyPolicySubDiv{
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-left: 1rem;
}

/* contact us */

.mainContent .contactUs {
  display: flex;
  flex-direction: column;
  background-color: var(--content-color);
  padding: 1rem 2rem;
  gap: 1rem;
  border-radius: 1rem;
  margin: 1rem;
  /* width: 50%; */
  box-shadow: 0px 4px 24px 0px #00000040;
  -moz-box-shadow: 0px 4px 24px 0px #00000040;
  -webkit-box-shadow: 0px 4px 24px 0px #00000040;
}

/* Custom component css */

/* Login Container */
[data-amplify-authenticator]{
  min-height: calc(100vh - 64px);
}

[data-amplify-router] {
  box-shadow: 0px 4px 24px 0px #00000040 !important;
  -moz-box-shadow: 0px 4px 24px 0px #00000040 !important;
  -webkit-box-shadow: 0px 4px 24px 0px #00000040 !important;
  border: none !important;
  border-radius: 8px !important;
}

/* [data-amplify-container] {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
} */

[data-amplify-container] .amplify-button[data-variation='primary']{
  background-color: #2C73EB;
}

[data-amplify-container] .amplify-textfield{
  --amplify-components-fieldcontrol-focus-border-color: #1565D8;
}

[data-amplify-container] .amplifyHeader{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.5rem;
  gap: 1rem;
  padding: 1rem 2rem;
}

[data-amplify-container] .amplifyFooter{
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
  gap: 1rem;
  padding: 1rem 2rem;
}

[data-amplify-container] .logos{
  display: flex;
  gap: 2rem;
  justify-content: center;
  flex-wrap: wrap;
}

/* Sidebar item selection color  */
.mainContent .sidebarContent .MuiListItemButton-root.Mui-selected{
  background-color: #84d4ff;
}

/* filter menu dropdown list */
.MuiMenu-root .MuiPaper-root .MuiList-root.MuiMenu-list{
  max-height: 50vh;
  overflow: auto;
}

/* table pagination */
.table .MuiTablePagination-toolbar{
  min-height: 0px;
}

/* media queries */
/* @media screen and (max-width: 1550px) {
  .mainContent .content .report .filters,
  .mainContent .content .studentsContent .filters{
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
  }
} */

@media screen and (max-width: 1250px) {
  .studentReport .reportContainer .reportContainerLeft{
    max-width: calc(100vw - 375px);
  }
}

@media screen and (max-width: 1200px) {

  /* .mainContent .content .innerContent .filters{
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
  } */

  .mainContent .content .innerContent .tabs .MuiTabs-root{
    width: 50vw;
  }
}

@media screen and (max-width: 1100px){
  .mainContent .sidebarContent{
    min-width: 30%;
    position: absolute;
    z-index: 10;
    min-height: calc(100vh - 64px);
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.65);
    -moz-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.65);
    -webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.65);
  }
  .mainContent .content{
    width: 97%;
  }
}

@media screen and (max-width: 950px) {
  .mainContent .content{
    width: 96%;
  }

  .mainContent .content .innerContent .tabs .MuiTabs-root{
    width: 40vw;
  }

  /* .mainContent .content .schoolContent .tabs .MuiTabs-root,
  .mainContent .content .facilitatorContent .tabs .MuiTabs-root,
  .mainContent .content .regionContent .tabs .MuiTabs-root{
    width: 40vw;
  } */

  .mainContent .content .report .chartArea .charts{
    width: 85vw;
  }

  .mainContent .content .report .chartArea .wordCloud .simpleCloud{
    max-width: 80vw;
    overflow: auto;
  }

}

@media screen and (max-width: 950px) and (orientation:landscape){
  .mainContent .content .report .chartArea .charts{
    height: 100vh;
    width: 80vw;
  }
}

@media screen and (max-width: 650px) and (orientation:portrait){
  .mainContent .content .report .chartArea .charts{
    height: 75vh;
    width: 80vw;
  }
}

@media screen and (max-width: 800px) {
  .studentReport .reportContainer{
    flex-direction: column-reverse;
  }

  .studentReport .reportContainer .reportContainerLeft{
    flex: unset;
    max-width: unset;
  }

  .studentReport .reportContainer .reportContainerLeft .paraContainer .paraDiv{
    flex-wrap: wrap;
  }

  .studentReport .reportContainer .reportContainerRight{
    flex-direction: row;
    flex: unset;
  }

  .studentReport .reportContainer .reportContainerRight .legendContainer{
    flex-direction: row;
  }
}

@media screen and (max-width: 700px) {
  /* dropbox */
  .dropbox .dropboxDetails{
    flex-direction: column;
  }

  .dropbox .dropboxDetails hr{
    border-right-width: 0;
    border-bottom-width: thin;
  }

  /* transfer student */
  .transferStudent .transferStudentDetails{
    flex-direction: column;
  }
  
  .transferStudent .transferStudentDetails hr{
    border-right-width: 0;
    border-bottom-width: thin;
  }

  /* delete student */
  .deleteStudent .deleteStudentDetails{
    flex-direction: column;
  }
  
  .deleteStudent .deleteStudentDetails hr{
    border-right-width: 0;
    border-bottom-width: thin;
  }

    /* edit student */
    .editStudent .editStudentDetails{
      flex-direction: column;
    }
    
    .editStudent .editStudentDetails hr{
      border-right-width: 0;
      border-bottom-width: thin;
    }
}

@media screen and (max-width: 650px) {

  .navbar .signedOutNavMenuDiv{
    display: flex;
  }

  .signedOutNavMenu{
    display: block;
  }

  .navbar .signedOutNav{
    display: none;
  }

  .mainContent .content{
    width: 95%;
  }

  .mainContent .content .innerContent .tabs{
    flex-direction: column-reverse;
    gap: 0.5rem;
  }

  .mainContent .content .innerContent .tabs .MuiTabs-root{
    width: 60vw;
  }

  /* .mainContent .content .innerContent .filters .forms,
  .mainContent .content .innerContent .filters .currentFilters{
    flex-direction: column;
    align-items: flex-start;
  } */

  .mainContent .content .excelUpload .upload {
    flex-direction: column;
    align-items: normal;
  }

  .mainContent .content .excelUpload .uploadStatus img{
    width: 100%;
    margin-left: 0rem;
  }

  .studentReport .reportContainer .reportContainerRight{
    flex-direction: column;
  }

  .studentReport .reportContainer .reportContainerRight .attemptContainer{
    flex-direction: row;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 570px) {
  .navbar .navHeader{
    height: 20px;
  }
  .navbar .MuiChip-root{
    width: 30vw;
  }
}

@media screen and (max-width: 510px){

  .mainContent .content{
    width: 93%;
  }

  .mainContent .content .excelUpload .upload .uploadFile {
    padding: 0.5rem;
    gap: 0.5rem;
  }
}

@media screen and (max-width: 500px){
  /* Login Container */
  [data-amplify-container] {
    /* position: absolute;
    top: 50%;
    left: 1%;
    right: 1%;
    transform: translate(0%,-50%);
    width: auto !important; */
    width: 90vw;
  }

  .mainContent .content .privacyPolicy{
    padding: 1rem 0;
  }

  .mainContent .content .privacyPolicy .privacyPolicyDiv .privacyPolicySubDiv{
    padding-left: 0;
  }
}

@media screen and (max-width: 450px) {
  .studentReport .reportContainer .reportContainerRight .attemptContainer,
  .studentReport .reportContainer .reportContainerRight .legendContainer{
    flex-direction: column;
  }
}

@media screen and (max-width: 430px) {
  .navbar .MuiChip-root{
    display: none;
  }

  .studentReport .reportContainer .reportContainerLeft .paraContainer audio {
    width: 100%;
  }
}

@media screen and (max-width: 340px) {
  .mainContent .content{
    width: 90%;
  }
}
